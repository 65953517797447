import React from 'react'
import { ALLDATA } from '../../../leg/all'
import { homeBanner } from '../../../jsons/allJsons'
import { Link } from 'react-router-dom'

const Banner = () => {
  return (
    <section className="slider-img">
		<div className="container">
			<div className="banner-box">
				<div className="row">
					<div className="col-lg-6 col-md-12 col-12">
						<div className="banner-box-insight">	
							<h2>{homeBanner.heading}s</h2>
							<p>
								{homeBanner.description}
							</p>
							<Link to={`/contact-us`} className="btn btn-orange">{ALLDATA.TALKTOOUREXPERTS}</Link>
						</div>
					</div>
					<div className="col-lg-6 col-md-12 col-12">
						<div className="vector-1">
							<img src={`/images/vector-1.png`} className="img-fluid" alt='vector' />
						</div>
						<div className="vector-2">
							<img src={`/images/vector-2.png`} className="img-fluid" alt='vector' />
						</div>
						<div className="vector-3">
							<img src={`/images/vector-3.png`} className="img-fluid" alt='vector'/>
						</div>
						
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default Banner
import React from 'react'

const ClientCard = ({data}) => {
  return (
        <div className="testimonial">
            <div className="testimonial-heading">
                <div className="testimonial-icon">
                    <img src={data.url} className="img-fluid" alt={data.name}/>                            </div>
                    <div className="insight-testimonial">
                        <h3>{data.name}</h3>
                        <p>{data.position}</p>
                    </div>
                </div>
            <div className="testimonial-description">
                <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
            </div>
        </div>
    )
}
export default ClientCard
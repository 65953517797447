import React from 'react'
import OurServices from '../components/services/our-services'
import DevelopmentCard from '../components/common/development-card'
import Development from '../components/services/development'
import VerticalTabs from '../components/services/verticat-tabs'

const Services = () => {
  return (
    <>
      <section className="services-bg"></section>
      <OurServices/>
      <VerticalTabs/>
      <DevelopmentCard/>
      <Development/>
    </>
  )
}

export default Services
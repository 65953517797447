import React from 'react'
import { Link } from 'react-router-dom'

const Card = ({data}) => {
  return (
    <Link to={`/services#${data.target}`} className='text-decoration'>
      <div className="grid-section">
        <div className="grid-icon">
        <img src={data.url} className="img-fluid" alt={data.name}/>
        </div>
        <h3>{data.name}</h3>
        <p  dangerouslySetInnerHTML={{ __html: data.description }}>
        </p>
      </div>
    </Link>
    
  )
}

export default Card
import React from 'react'

const ContactFormController = () => {
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [formData, setFormData] = React.useState({
      name: '',
      email: '',
      message: '',
    });
    const [loading, setLoading] = React.useState(false);  // Loading state
    const [errors, setErrors] = React.useState({}); // Error state
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      // Clear the error message for the field being edited
      setErrors({
        ...errors,
        [e.target.name]: '', // Clear the error for the field being edited
      });
    };
  
    const validateForm = () => {
      const newErrors = {};
      // Validation rules
      if (!formData.name) {
        newErrors.name = 'Full Name is required.';
      }
      if (!formData.email) {
        newErrors.email = 'Email is required.';
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = 'Email format is invalid.';
      }
      if (!formData.message) {
        newErrors.message = 'Description is required.';
      }
      return newErrors;
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return; // Stop form submission if there are validation errors
      }
  
      setLoading(true); // Start loading when form is submitted
  
      // Create a FormData object to handle form data
      const form = new FormData();
      form.append('name', formData.name);
      form.append('email', formData.email);
      form.append('message', formData.message);
  
      try {
        const response = await fetch('https://arthainfinity.com/backend/index.php', {
          method: 'POST',
          body: form, // FormData object is automatically sent as multipart/form-data
        });
       
        const result = await response.json();
        if (result.status) {
          setSuccessMessage('Thank you for reaching out! We will get back to you soon.')
          // Reset form data and errors
          setFormData({ name: '', email: '', message: '' });
          setErrors({});
          setTimeout(() => setSuccessMessage(''), 5000);
        } else {
          setErrorMessage('There was an error submitting the form. Please try again.')
          setTimeout(() => setErrorMessage(''), 5000);
        }
      } catch (error) {
        setErrorMessage('There was an error submitting the form. Please try again.')
        setTimeout(() => setErrorMessage(''), 5000);
      } finally {
        setLoading(false); // Stop loading when request is completed
      }
    };
  return {
        handleChange,
		handleSubmit,
		loading,
		formData,
		successMessage,
		errorMessage,
		setSuccessMessage,
		setErrorMessage,
		errors
  }
}

export default ContactFormController

import React from 'react'
import ContactDetails from './contact-details'
import ContactForm from './contact-form'

const Contact = () => {
  return (
    <section className="space-section">
		<div className="container">
			
			<div className="row mb-4">
				<div className="col-md-6">
                    <ContactDetails/>
				</div>
				<div className="col-md-6">
					<ContactForm/>
				</div>
				
			</div>
		</div>
	</section>
  )
}

export default Contact

import React from 'react'
import { Link, NavLink } from "react-router-dom";
import { navBar } from '../../../jsons/allJsons';
import { ALLDATA } from '../../../leg/all';
const Header = () => {
  return (
    <header className="header-dark">
        <div className="container">
            <div className="row">
                <div className="">
                    <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">
                            <img src={`/images/logo.png`} alt='Logo'/>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                        <ul className="navbar-nav mb-2 mb-lg-0 m-r-40">
                            {navBar.map((nav)=>(
                                <li className="nav-item" key={nav.id}>
                                    <NavLink
                                        className={({ isActive }) =>
                                        `nav-link ${isActive ? "text-blue" : ""}`
                                        }
                                        to={nav.slug}
                                >
                                    {nav.name}
                                </NavLink>
                                </li>
                            ))}
                        </ul>
                        <Link to="/contact-us" className="btn btn-orange">{ALLDATA.REQUESTAQUOTE}</Link>
                        </div>
                    </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
  )
}

export default Header
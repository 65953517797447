import React from 'react'
import { developmentCard } from '../../../jsons/allJsons'
import { Link } from 'react-router-dom'

const DevelopmentCard = () => {
  return (
    <Link to={`/services#development`} className='text-decoration'>
        <section className="development-section space-section">
            <div className="container">
                <div className="row d-flex justify-content-center">
                {developmentCard.map((development, index)=>(
                    <div className="col-md-3 d-flex" key={development.id}>
                        <div className={`custom-development ${index ===0 ? 'active' :""}`}>
                            <div className="custom-development-title">
                            <h3>{development.name}</h3>
                            </div>
                            <div className="icon-star">
                                <img src={`${development.url}`} className="img-fluid" alt={development.name}/>                        
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </section>
    </Link>
    
  )
}

export default DevelopmentCard
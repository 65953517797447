import React from 'react'

const MainHeading = ({name}) => {
  return (
    <>
     <h1 className="heading float-left mb-0">{name}</h1>
     <img src={`/images/circle-icon.png`} className="img-fluid" alt='Circle'/>
    </>
  )
}

export default MainHeading
import React from 'react'
import Banner from '../components/home/banner'
import ErpServices from '../components/home/erp-services'
import Industries from '../components/home/industries'
import DevelopmentCard from '../components/common/development-card'
import CountersSection from '../components/common/counter'
import Infor from '../components/home/infor'
import OurClients from '../components/common/our-clients'
import ContactHelp from '../components/home/contact-help'
const Home = () => {

  return (
    <>
      <Banner/>
      <ErpServices/>
      <Industries/>
      <DevelopmentCard/>
      <CountersSection/>
      <Infor/>
      <OurClients/>
      <ContactHelp/>
    </>
  )
}
export default Home

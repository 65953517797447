import React from 'react'

const SmallHeading = ({heading, name}) => {
  return (
   <>
    <div className="sub-title">
        <p>{heading}</p>
        <img src={`/images/circle-icon.png`} className="img-fluid" alt='Circle' />
    </div>
    {name && <h1 className="heading">
        {name}
    </h1>}
 </>
  )
}

export default SmallHeading
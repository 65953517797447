import React, { useEffect, useState } from 'react';
import { implementationServices } from '../../../jsons/allJsons';
import LazyLoading from '../../../hooks/lazyLoading';
import { useLocation } from 'react-router-dom';

const VerticalTabs = () => {
const location = useLocation();
    const hash = location.hash.slice(1);
    const { isLoading, handleImageLoad } = LazyLoading();

    const getInitialTab = () => {
        if (hash === 'implementationServices') return 0;
        if (hash === 'dataMigrationServices') return 1;
        if (hash === 'applicationManagementServices') return 2;
        if (hash === 'applicationAuditServices') return 3;
        return 0; 
    };
    const [activeTab, setActiveTab] = useState(getInitialTab);

    const handleTabClick = (index) => {
        console.log(`Tab clicked: ${index}`);
        setActiveTab(index); 
    };

    useEffect(() => {
        const tabIndex = getInitialTab();
        if (tabIndex !== activeTab) {
            setActiveTab(tabIndex); 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash]); 


    return (
        <> 
            <section className="space-section pt-1">
                <div className="container">
                <div className="row">
                        <div className="col-md-12">
                            <div className="tablist-section">
                                <ul className="tab-list">
                                    {implementationServices.map((our,index)=>(
                                         <li
                                         key={index}
                                         onClick={() => handleTabClick(index)}
                                         className={`cursor ${activeTab === index ? 'active' : ''}`}
                                     >
                                         {our.label}
                                     </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="space-section tab-detail" id={implementationServices[activeTab].content.target}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sub-title">
                                <h3>{implementationServices[activeTab].content.name}</h3>
                                <img src={`/images/circle-icon.png`} className="img-fluid" alt='circle'/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="aboutus-section">
                                <p>{implementationServices[activeTab].content.description}</p>
                            </div>
                            <div className="listing">
                                <h5>{implementationServices[activeTab].content.expertise.name}</h5>
                                <ul>
                                    {implementationServices[activeTab].content.expertise.data.map((expertise)=>(
                                        <li key={expertise.id}>
                                            <div className="counting">
                                                <div className="srnumber">
                                                    <p>{expertise.count}</p>    
                                                </div>
                                                <p>{expertise.name}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className={`col-md-5 ${isLoading?'loader-insight':''}`}>
                            {isLoading &&  
                            <img 
                                src="/images/loading-waiting.gif" 
                                className="img-fluid" 
                                alt="Loading..."
                            />}
                            <img src={implementationServices[activeTab].content.url} 
                                className="img-fluid" 
                                alt={implementationServices[activeTab].content.name}
                                style={isLoading ? { display: "none" } : {}} 
                                onLoad={handleImageLoad} 
                                onError={handleImageLoad}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
  );
};

export default VerticalTabs;

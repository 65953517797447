
import './App.css';
import {  BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import ContactUs from './pages/contactUs';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import PageNotFound from './pages/pageNotFound';
import Header from './components/common/header';
import Footer from './components/common/footer';
import Services from './pages/services';
import Industries from './pages/industries';
import ScrollToTop from './components/common/scroll-to-top';

function App() {
  return (
    <Router>
      <ScrollToTop /> 
      <Header />
      <Routes>
        <Route  exact path="/" element={<Home/>} />
        <Route  path="/about" element={<About/>} />
        <Route  path="/services" element={<Services/>} />
        <Route  path="/industries" element={<Industries/>} />
        <Route  path="/contact-us" element={<ContactUs/>} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer/>
  </Router>
  );
}

export default App;

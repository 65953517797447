import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation(); // Get the current location

  useEffect(() => {
    if (location.hash) {
      // If there's a hash, scroll to the corresponding element
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Otherwise, scroll to the top
      window.scrollTo(0, 0);
    }
  }, [location]); // Re-run whenever the location changes
  return null; // This component doesn't render anything
};

export default ScrollToTop;
import React from 'react'
import { ourServices } from '../../../jsons/allJsons'
import MainHeading from '../../common/heading/mainHeading'

const OurServices = () => {
  return (
    <section className="space-section pb-0">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center mb-3">
                    <div className="sub-title justify-content-center align-items-center">
                        <MainHeading name={ourServices.name}/>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-md-10 text-center mb-5">
                    <p>{ourServices.description}</p>
                </div>
            </div>
        
        </div>
    </section>
  )
}
export default OurServices

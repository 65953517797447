import React from 'react'
import { chooseUs } from '../../../jsons/allJsons'
import MainHeading from '../../common/heading/mainHeading'

const ChooseUs = () => {
  return (
    <section className="space-section">
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center mb-3">
                <div className="sub-title justify-content-center align-items-center mb-5">
                    <MainHeading name={chooseUs.name}/>
                </div>
            </div>
        </div>
        <div className="row">
            {chooseUs.data.map((choose)=>(
                <div className="col-md-3 d-flex" key={choose.id}>
                    <div className="why-choose-us">
                        <div className="why-choose-icon">
                            <img src={choose.url} className="img-fluid" alt={choose.name}/>
                        </div>
                        <h3>{choose.name}</h3>
                        <p>{choose.description}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>
</section>
  )
}

export default ChooseUs

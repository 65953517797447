import React from "react";
import SmallHeading from "../../common/heading/smallHeading";
import { erpServices } from "../../../jsons/allJsons";
import Card from "./card";

const ErpServices = () => {
  return (
    <section className="terms bg-grey  space-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <SmallHeading heading={erpServices.heading} name={erpServices.name}/>
          </div>
        </div>
        <div className="row">
          {erpServices.data.map((erp)=>(
            <div className="col-md-3 d-flex" key={erp.id}>
            <Card data={erp}/>
          </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ErpServices;

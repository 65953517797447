import React from "react";
import { useState, useEffect } from "react";
import { counter } from "../../../jsons/allJsons";
const Counter = ({ endValue, duration, suffix }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const incrementTime = duration / endValue;

    const counter = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === endValue) {
        clearInterval(counter);
      }
    }, incrementTime);

    return () => clearInterval(counter);
  }, [endValue, duration]);

  return (
    <h4>
      {count}
      {suffix}
    </h4>
  );
};

const CountersSection = () => {
  return (
    <section className="count-down space-section">
        <div className="container">
          <div className="row d-flex justify-content-center">
            {counter.map((count)=>(
              <div className="col-md-2" key={count.id}>
                <div className="counter-box">
                  <Counter endValue={count.count} duration={1000} suffix="+" />
                  <p>{count?.name ?? "NA"}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
    </section>
  
  );
};

export default CountersSection;

import React from "react";
import WhoWeAre from "../components/about/who-we-are";
import OurTeam from "../components/about/our-team";
import OurClients from '../components/common/our-clients'
import ChooseUs from "../components/about/choose-us";
const About = () => {
  
  return (
    <>
      <section className="about-bg"></section>
      <WhoWeAre/>
      <OurTeam/>
      <ChooseUs/>
      <OurClients/>
    </>
  ) 
};

export default About;
import React from 'react'
import { footerData } from '../../../jsons/allJsons'
import { ALLDATA } from '../../../leg/all'

const Footer = () => {
  return (
    <footer className="space-section footer pb-3">
        <div className="container-fluid">
            <div className="row d-flex justify-content-center text-center">
                <div className="col-lg-3">
                	<img src={`/images/call.png`} className="img-fluid mb-3" alt='Call'/>
                    <a href={`tel:${footerData.mobileNumber}`} className="text-white text-decoration">
                        <p>{footerData.mobileNumber}</p>
                    </a>
                </div>
                <div className="col-lg-3">
                	<img src={`/images/envelope.png`} className="img-fluid mb-3" alt='Envelope'/>
                    <a href={`mailto:${footerData.email}`} className="text-white text-decoration">
                        <p>{footerData.email}</p>
                    </a>
                </div>
                <div className="col-lg-3">
                	<img src={`/images/map-location.png`} className="img-fluid mb-3" alt='Location'/>
                    <p dangerouslySetInnerHTML={{ __html: footerData.address }}></p>
                </div>
            </div>
            <div className="row d-flex justify-content-center p-2">
                <div className="col-md-10 text-center">
                <p>{ALLDATA.COPYRIGHT}</p>
                </div>
            </div>
            <div className="row d-flex justify-content-center p-2">
                <div className="col-md-10 text-center">
					<ul className="social-icon">
                        {footerData.socialLink.map((link)=>(
                            <li key={link.id}>
                                <a target="_blank" rel="noreferrer" href={link.link}>
                                    <img src={link.url} className="img-fluid" alt={link.name}/>
                                </a>
                                
                            </li>
                        ))} 
                    </ul>
                </div>
            </div>
        </div>
    </footer>

   
  )
}

export default Footer
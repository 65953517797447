import React from 'react'
import TeamMember from './teamMember'
import { ourTeam } from '../../../jsons/allJsons'
import MainHeading from '../../common/heading/mainHeading'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const OurTeam = () => {
    const responsive = {
		superLargeDesktop: {
		  // the naming can be any, depends on you.
		  breakpoint: { max: 4000, min: 3000 },
		  items: 5
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 4
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 2
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1
		}
	  };
  return (
    <section className="space-section leadership">
        <div className="container">
            <div className="row">
            	<div className="sub-title justify-content-center align-items-center mb-5">
                    <MainHeading name={ourTeam.heading}/>
				</div>
            </div>
            <Carousel  
                swipeable={false}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={ true}
                keyBoardControl={true}
                customTransition="transform 500ms ease-in-out"
                transitionDuration={500}
                containerclassName="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={'desktop'}
                itemclassName="mx-2"
            >
                {ourTeam.teams.map((team)=>(
                    <TeamMember data={team} key={team.id}/>
                ))}
		    </Carousel>
        </div>
    </section>
  )
}

export default OurTeam

import React from 'react'

const LazyLoading = () => {
    const [isLoading, setIsLoading] = React.useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
      };
  return {isLoading, handleImageLoad}
}

export default LazyLoading
import React from 'react'
import { developments } from '../../../jsons/allJsons'
const Development = () => {
  return (
    <section className="space-section service-development" id='development'>
		<div className="container">
			<div className="row mt-5">
				{developments.map((developmen)=>(
					<div className="col-md-4 d-flex" key={developmen.id}>
						<div className="erp-solution text-center">
							<div className="icon max-auto">
								<img src={developmen.url} className="img-fluid" alt={developmen.name}/>
							</div>
							<h5>{developmen.name}</h5>
							<p>{developmen.description}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	</section>
  )
}
export default Development

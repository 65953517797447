import React from 'react'
import White from '../sections/white'
import { allIndustries } from '../../../jsons/allJsons'

const Automotive = () => {
  return (
    <White data={allIndustries.automotive}/>
  )
}

export default Automotive

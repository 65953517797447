
export const homeBanner={
    id:1,
    heading:"Transforming Your Software & ERP for Tomorrow's Business Needs",
    description:"50+ Expert Software Engineers & Business Consultants with 20+ Years of Proven Business Transformation Experience"
}
export const erpServices = {
    heading:"Infor ERP LN Services",
    name:"Empowering Your Business Transformation.",
    data:[
    {
        id:1,
        url:`/images/implementing.png`,
        name:'Implementation Services',
        description:`At Artha Infinity, we specialize in the implementation of Infor ERP LN, a comprehensive enterprise
        resource planning solution designed to optimize and streamline your business operations. Whether
        you're a large-scale manufacturer, a distribution company, or a service provider, our tailored approach
        ensures that Infor ERP LN is configured to meet the specific needs of your organization, driving
        efficiency, and enhancing productivity.`,
        target:"implementationServices"
    },
    {
        id:2,
        url:`/images/data.png`,
        name:'Data Migration Services',
        description:`Data migration is a critical component of any ERP implementation, and at Atha Infinity, we understand
            that your data is the backbone of your business operations. Our Infor ERP LN Data Migration Services
            ensure a smooth, accurate, and efficient transfer of your valuable data from legacy systems to Infor
            ERP LN, minimizing downtime and preserving the integrity of your information.`,
        target:"dataMigrationServices"
    },
    {
        id:3,
        url:`/images/app-development.png`,
        name:'Application Management Services',
        description:`At Artha Infinity, we offer comprehensive Application Management Services (AMS) for Infor LN ERP
            to ensure that your system continues to operate at peak performance, allowing you to focus on your
            core business. Application Management Services (AMS) encompass the continuous support,
            maintenance, and enhancement of your ERP system. Our AMS offerings for Infor LN ERP are designed
            to provide your business with the technical expertise and operational support needed to keep your
            ERP system running smoothly, efficiently, and securely.`,
        target:"applicationManagementServices"
    },
    {
        id:4,
        url:`/images/audition.png`,
        name:'Application Audit Services',
        description:`Ensure Optimal Performance and Compliance with Our Comprehensive ERP Audit Services.
        Implementing an ERP system is a significant investment in your business's future, but the work doesn't
        end once the system goes live. At Artha Infinity, we offer specialized Audit Services for Post-
        Implemented ERP Solutions to ensure your ERP system is performing at its best, delivering the value
        you expected, and adhering to compliance standards.`,
        target:"applicationAuditServices"
    },
]}

// Industries
export const industries = [
    {
        id:1,
        count:'01',
        name:'Aerospace & Defense',
        description:`We specialize in the implementation of Infor ERP LN, a comprehensive enterprise resource planning solution designed to optimize and streamline your business operations.`,
        target:"aerospace"
    },
    {
        id:2,
        count:'02',
        name:'Automobile',
        description:`•We understand that your data is the backbone of your business operations. Our Infor ERP LN Data Migration Services ensure a smooth, accurate, and efficient transfer of your valuable data from legacy systems to Infor ERP LN.`,
        target:"automobile"
    },
    {
        id:3,
        count:'03',
        name:'Industrial Equipment & Machinery.',
        description:`We offer comprehensive Application Management Services (AMS) for Infor LN ERP to ensure that your system continues to operate at peak performance, allowing you to focus on your core business.`,
        target:"industrial"
    },
    {
        id:4,
        count:'04',
        name:'Distribution',
        description:`we offer specialized Audit Services for Post-Implemented ERP Solutions to ensure your ERP system is performing at its best, delivering the value you expected, and adhering to compliance standards.`,
        target:"distribution"
    },
    {
        id:5,
        count:'05',
        name:'Engineering & Construction',
        description:`We carry more than just good coding skills. Our experience makes us stand out from other software development.`,
        target:"engineering"
    },
    {
        id:6,
        count:'06',
        name:'Food & Beverages',
        description:`Create complex enterprise software, ensure reliable software integration, modernize your legacy system.`,
        target:"food"
    },
]
// DevelopmentCard
export const developmentCard = [
    {
        id:1,
        url:`/images/custom.png`,
        name:'Custom Software Development',
    }, 
    {
        id:2,
        url:`/images/outsourcing.png`,
        name:'Outsourcing Development',
    }, 
    {
        id:3,
        url:`/images/product.png`,
        name:'Product Development',
    }, 
]
// counter
export const counter =[
    {
        id:1,
        count:10,
        name:'Active Clients',
    },  
    {
        id:2,
        count:100,
        name:'Projects Done',
    },  
    {
        id:3,
        count:25,
        name:'Domain Expert',
    },  
    {
        id:4,
        count:5,
        name:'Glorious Years',
    }
]
// infor
export const infor={
    id:1,
    description:`<p>Infor is a global leader in cloud software solutions, offering </p><p> industry-specific suites that streamline operations, boost efficiency,</p><p> and enhance user experience. Their products integrate seamlessly</p><p> with existing systems, providing flexibility and scalability.</p>`,
    url:`/images/infor-logo.png`
}
// client
export const client = [
    {
        id:1,
        name:'Moonkle LTD',
        position:"Client of Company",
        description:`Very well thought out and articulate communication. Clear milestones, deadlines, and fast work.
        Patience. Infinite patience. No shortcuts. Even if the client is being careless. The best part...always
        solving problems with great original ideas.`,
        url:`/images/testimonial-user.png`
    },
    {
        id:2,
        name:'SoftTech',
        position:"Manager of Company",
        description:`Patience. Infinite patience. No shortcuts. Very well thought out and articulate communication. Clear
        milestones, deadlines, and fast work. Even if the client is being careless. The best part...always solving
        problems with great original ideas.`,
        url:`/images/testimonial-user.png`
    },
    {
        id:3,
        name:'Aerospace and Defence Industry',
        position:"CIO",
        description:`Implementing an ERP system has been a game-changer for our aerospace operations. It streamlined
        our processes, improved compliance with industry regulations, and gave us real-time visibility into
        every aspect of our business. The system’s automation has saved time, reduced errors, and enhanced
        decision-making, making us more agile and competitive in a complex industry.`,
        url:`/images/testimonial-user.png`
    },
    {
        id:4,
        name:'Automotive Industry',
        position:"CTO",
        description:`Implementing an ERP system in our automotive business has transformed the way we operate. It
        streamlined our supply chain, optimized production planning, and provided real-time insights into
        every aspect of our operations. The system's automation has reduced manual errors and improved
        efficiency, allowing us to meet industry demands faster and more effectively. It's been a key driver in
        enhancing our competitiveness and growth.`,
        url:`/images/testimonial-user.png`
    }
]
// contect help
export const contectHelp = {
    id:1,
    name:'Do you have any project?',
    description:`Contact us for help with <br/> your finances.`
}
// navbar
export const navBar =[
   
    {
        id:1,
        name:'About Us',
        slug:'/about'
    },
    {
        id:2,
        name:'Services',
        slug:'/services'
    },
    {
        id:3,
        name:'Industries',
        slug:'/industries'
    },
    {
        id:4,
        name:'Contact Us',
        slug:'/contact-us'
    }
]
// footer
export const footerData ={
    id:1,
    mobileNumber:'+31-623188849',
    email:"infor@arthainfinity.com",
    address:`24 Zevenaarlaan, 5651 BA,<br> Eindhoven, The Netherlands`,
    socialLink:[
        {
            id:1,
            url:`/images/facebook-sm.png`,
            name:'Facebook',
            link:"https://facebook.com/"
        },
        {
            id:2,
            url:`/images/twitter-sm.png`,
            name:'Twitter',
            link:"https://x.com/"
        },
        {
            id:3,
            url:`/images/linkedln.png`,
            name:"Linkedln",
            link:"https://www.linkedin.com/company/artha-infinity/?viewAsMember=true"
        },
        {
            id:4,
            url:`/images/instagram.png`,
            name:"Instagram",
            link:"https://www.instagram.com/"
        }
    ]

}
// Who we are
export const WhoWeAreData ={
    id:1,
    heading:"Who we are",
    name:"Your innovation friend",
    description:`Artha Infinity is a Digital Transformation Services and Solutions provider dedicated to helping our clients enhance operational efficiency, and boost customer responsiveness. With a strong focus on customer-centric solutions, we assist businesses in integrating their operations and overcoming real-world challenges by  leveraging cutting-edge technologies. Our service offerings include consulting & implementation services for Infor ERPLN. Additionally, We develop custom software, Website, Web-Apps and mobile apps tailored to meet specific business needs`,
    url:`/images/about-1.png`
}
// Our Team
export const ourTeam ={
    id:1,
    heading:"Our Leadership team",
    teams:[
        {
            id:1,
            name:'Kapil Gupta',
            position:"Founder",
            url:`/images/kapil-gupta.png`,
            socialLinks:[
                {
                    id:1,
                    name:'Facebook',
                    url:`/images/facebook-white.png`,
                    link:''
                },
                {
                    id:2,
                    name:'Instagram',
                    url:`/images/instagram-white.png`,
                    link:''
                },
                {
                    id:3,
                    name:'Linkedin',
                    url:`/images/linkedin-white.png`,
                    link:''
                },
                {
                    id:4,
                    name:'Twitter',
                    url:`/images/twitter-white.png`,
                    link:''
                }
            ]
        },
        {
            id:2,
            name:'Vaibhav Gupta',
            position:"Founder & CEO",
            url:`/images/vaibhav-gupta.png`,
            socialLinks:[
                {
                    id:1,
                    name:'Facebook',
                    url:`/images/facebook-white.png`,
                    link:''
                },
                {
                    id:2,
                    name:'Instagram',
                    url:`/images/instagram-white.png`,
                    link:''
                },
                {
                    id:3,
                    name:'Linkedin',
                    url:`/images/linkedin-white.png`,
                    link:''
                },
                {
                    id:4,
                    name:'Twitter',
                    url:`/images/twitter-white.png`,
                    link:''
                }
            ]
        },{
            id:3,
            name:'Raghav Gupta',
            position:"CFO",
            url:`/images/raghav-gupta.png`,
            socialLinks:[
                {
                    id:1,
                    name:'Facebook',
                    url:`/images/facebook-white.png`,
                    link:''
                },
                {
                    id:2,
                    name:'Instagram',
                    url:`/images/instagram-white.png`,
                    link:''
                },
                {
                    id:3,
                    name:'Linkedin',
                    url:`'/images/linkedin-white.png`,
                    link:''
                },
                {
                    id:4,
                    name:'Twitter',
                    url:`/images/twitter-white.png`,
                    link:''
                }
            ]
        },
        {
            id:4,
            name:'Palak Gupta',
            position:"VP - Sales & Marketing Head",
            url:`/images/palak-gupta.png`,
            socialLinks:[
                {
                    id:1,
                    name:'Facebook',
                    url:`/images/facebook-white.png`,
                    link:''
                },
                {
                    id:2,
                    name:'Instagram',
                    url:`/images/instagram-white.png`,
                    link:''
                },
                {
                    id:3,
                    name:'Linkedin',
                    url:`/images/linkedin-white.png`,
                    link:''
                },
                {
                    id:4,
                    name:'Twitter',
                    url:`/images/twitter-white.png`,
                    link:''
                }
            ]
        }
    ]
}
// ChooseUs
export const chooseUs = {
    id:1,
    name:'Why choose us?',
    data:[
        {
            id:1,
            name:'Experience',
            url:`/images/experience.png`,
            description:"Our team of more than 1400 software experts"
        },
        {
            id:2,
            name:'Quick Support',
            url:`/images/support.png`,
            description:"We'll help you test bold new ideas while sharing your."
        },
        {
            id:3,
            name:'Best Solution',
            url:`/images/solution.png`,
            description:"Our team of more than 1400 software expert"
        },
        {
            id:4,
            name:'Unique Design',
            url:`/images/design.png`,
            description:"We'll help you test bold new ideas while sharing your."
        },
    ]
}
// OurServices
export const ourServices = {
    id:1,
    name:"Our Services",
    description:"Techpanda is committed to provide secure, reliable and scalable mobile and web based business solutions, aimed at high customer satisfaction. Your Idea is secure with us by a non-disclosure agreement.",

}
// ImplementationServices
export const implementationServices = [
    {
        id:1,
        label: "Implementation Services",
        content:{
            name:"Implementation Services",
            description:"At Artha Infinity, we specialize in the implementation of Infor ERP LN, a comprehensive enterprise resource planning solution designed to optimize and streamline your business operations. Whether you're a large-scale manufacturer, a distribution company, or a service provider, our tailored approach ensures that Infor ERP LN is configured to meet the specific needs of your organization, driving efficiency, and enhancing productivity.",
            target:"implementationServices",
            url:`/images/services-1.png`,
            expertise:{
                name:'Our Expertise',
                data:[
                    {
                        id:1,
                        count:'01',
                        name:"Our Implementation Process Includes."
                    },
                    {
                        id:2,
                        count:'02',
                        name:"Consultation and Assessment"
                    },
                    {
                        id:3,
                        count:'03',
                        name:"Planning and Strategy"
                    },
                    {
                        id:4,
                        count:'04',
                        name:"Customization and Configuration"
                    },
                    {
                        id:5,
                        count:'05',
                        name:"Data Migration"
                    },
                    {
                        id:6,
                        count:'06',
                        name:"Training and Support"
                    },
                    {
                        id:7,
                        count:'07',
                        name:"Go-Live and Beyond"
                    }
                ]
            }
        }
    },
    {
        id:2,
        label: "Data Migration Services",
        content:{
            name:"Data Migration Services",
            description:"Data migration is a critical component of any ERP implementation, and at Atha Infinity, we understand that your data is the backbone of your business operations. Our Infor ERP LN Data Migration Services ensure a smooth, accurate, and efficient transfer of your valuable data from legacy systems to Infor ERP LN, minimizing downtime and preserving the integrity of your information.",
            target:'dataMigrationServices',
            url:`/images/services-1.png`,
            expertise:{
                name:'Our Expertise',
                data:[
                    {
                        id:1,
                        count:'01',
                        name:"Assessment and Planning"
                    },
                    {
                        id:2,
                        count:'02',
                        name:"Data Mapping and Transformation"
                    },
                    {
                        id:3,
                        count:'03',
                        name:"Data Cleansing and Validation"
                    },
                    {
                        id:4,
                        count:'04',
                        name:"Test Migrations"
                    },
                    {
                        id:5,
                        count:'05',
                        name:"Data Migration Execution"
                    },
                    {
                        id:6,
                        count:'06',
                        name:"Post-Migration Validation"
                    },
                    {
                        id:7,
                        count:'07',
                        name:"Ongoing Support and Optimization"
                    }
                ]
            }
        }
    },
    {
        id:3,
        label: "Application Management Services",
        content:{
            name:"Application Management Services",
            description:"At Artha Infinity, we offer comprehensive Application Management Services (AMS) for Infor LN ERP to ensure that your system continues to operate at peak performance, allowing you to focus on your core business. Application Management Services (AMS) encompass the continuous support, maintenance, and enhancement of your ERP system. Our AMS offerings for Infor LN ERP are designed to provide your business with the technical expertise and operational support needed to keep your ERP system running smoothly, efficiently, and securely.",
            target:"applicationManagementServices",
            url:`/images/services-1.png`,
            expertise:{
                name:'Our Expertise',
                data:[
                    {
                        id:1,
                        count:'01',
                        name:"System Monitoring and Maintenance"
                    },
                    {
                        id:2,
                        count:'02',
                        name:"Technical & Functional Support and Troubleshooting"
                    },
                    {
                        id:3,
                        count:'03',
                        name:"Performance Optimization"
                    },
                    {
                        id:4,
                        count:'04',
                        name:"Custom Development and Enhancements"
                    },
                    {
                        id:5,
                        count:'05',
                        name:"User Training and Support"
                    },
                    {
                        id:6,
                        count:'06',
                        name:"Strategic Consulting"
                    }
                ]
            }
        }
    },
    {
        id:4,
        label: "Application Audit Services",
        content:{
            name:"Application Audit Services",
            description:"Ensure Optimal Performance and Compliance with Our Comprehensive ERP Audit Services. Implementing an ERP system is a significant investment in your business's future, but the work doesn't end once the system goes live. At Artha Infinity, we offer specialized Audit Services for Post- Implemented ERP Solutions to ensure your ERP system is performing at its best, delivering the value you expected, and adhering to compliance standards.",
            target:"applicationAuditServices",
            url:`/images/services-1.png`,
            expertise:{
                name:'Our Expertise',
                data:[
                    {
                        id:1,
                        count:'01',
                        name:"Performance Evaluation"
                    },
                    {
                        id:2,
                        count:'02',
                        name:"Security & Compliance Audit"
                    },
                    {
                        id:3,
                        count:'03',
                        name:"Process & Workflow Analysis"
                    },
                    {
                        id:4,
                        count:'04',
                        name:"Data Integrity & Accuracy Review"
                    },
                    {
                        id:5,
                        count:'05',
                        name:"System Configuration & Customization Review"
                    },
                    {
                        id:6,
                        count:'06',
                        name:"User Adoption & Training Assessment"
                    }
                ]
            }
        }
    } 
    
]
// Development
export const developments =[
    {
        id:1,
        name:"Custom Software Development",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting we industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
        url:`/images/custom.png`
    },
    {
        id:2,
        name:"Outsourcing Development",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting we industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
        url:`/images/outsourcing.png`
    },
    {
        id:3,
        name:"Product Development",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting we industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and",
        url:`/images/product.png`
    }
]
// Aerospace
export const allIndustries = {
    aerospace:{
        id:1,
        name:"Aerospace & Defence",
        description:"The aerospace and defense (A&D) industry is one of the most complex and regulated sectors globally. Companies operating in this space face challenges such as strict compliance requirements, intricate supply chains, complex project management, and ever-increasing demand for product innovation. At Artha Infinity we are specialized in Implementing a robust Enterprise Resource Planning (ERP) solution for streamlining operations, ensuring regulatory compliance, and enhancing competitiveness in this challenging landscape.",
        url:`/images/1.png`,
        target:'aerospace'
    },
    automotive:{
        id:1,
        name:"Automotive",
        description:`<p class="mb-1">The automobile industry is constantly evolving, driven by the demand for innovation, stringent regulatory standards, and the complexities of global supply chains. Managing operations in such a dynamic environment requires a robust Enterprise Resource Planning (ERP) solution.</p> <p>At Artha Infinity, we provide ERP solutions that are specifically designed to meet the unique challenges of the automotive industry, helping businesses optimize their processes, reduce costs, and improve overall performance</p>`,
        url:`/images/2.png`,
        target:'automotive'
    },
    industrialEquipment:{
        id:1,
        name:"Industrial Equipment & Machinery",
        description:`At Artha Infinity, we specialize in delivering cutting-edge solutions tailored to the unique demands of the Heavy Machinery industry. Leveraging our deep industry knowledge and a proven track record, we help organizations navigate the complexities of digital transformation with confidence and precision.`,
        url:`/images/3.png`,
        target:'industrial'
    },
    engineering:{
        id:1,
        name:"Engineering & Construction.",
        description:`Managing large-scale projects, coordinating resources, and ensuring compliance in the engineering and construction industry can be complex. Our ERP solutions streamline project management, optimize resource allocation, and provide real-time visibility into every phase of your project. From budgeting and scheduling to contract management and compliance tracking, our ERP systems help you deliver projects on time and within budget.`,
        url:`/images/engineering.avif`,
        target:'engineering'
    },
    distribution: {
        id:1,
        name:"Distribution.",
        description:`The distribution industry is the lifeblood of the global economy, connecting manufacturers with retailers and consumers through complex supply chains. However, managing large inventories, coordinating logistics, maintaining product traceability, and meeting customer demands require a high level of efficiency. At Artha Infinity we Implement a robust Enterprise Resource Planning (ERP) solution, which allows distribution companies to streamline operations, optimize supply chains, and improve customer satisfaction, all while reducing operational costs`,
        url:`/images/5.png`,
        target:'distribution'
    }, 
    food:{
        id:1,
        name:"Food & Beverages..",
        description:`In the fast-paced food and beverage industry, maintaining product quality, ensuring regulatory compliance, and managing a complex supply chain are critical. Our ERP solutions are designed to provide real-time visibility into your operations, from inventory management to production scheduling and traceability, helping you meet regulatory standards and optimize efficiency.`,
        url:`/images/food.avif`,
        target:'food'
    }
}
// contact
export const contatctData ={
    contactdetails:{
        heading:'contact details',
        name:"Contact Us",
        description:"Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions."

    },
    contactForm:{
        name:"Ready to Get Started?",
        description:"Your email address will not be published. Required fields are marked",
    }
}
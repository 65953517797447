import React from 'react'
import SmallHeading from '../../common/heading/smallHeading'
import { contatctData, footerData } from '../../../jsons/allJsons'

const ContactDetails = () => {
  return (
    <>
      <SmallHeading heading={contatctData.contactdetails.heading} name={contatctData.contactdetails.name}/>
      <p>{contatctData.contactdetails.description}</p>
      <ul className="footer-list">
        <li>
          <div className="contact-icon">
            <img src={`/images/phone-1.png`} className="img-fluid" alt='Phone'/>
            <a href={`tel:${footerData.mobileNumber}`} className="text-black text-decoration">
              {footerData.mobileNumber}
            </a>
          </div>
        </li>
        <li>
          <div className="contact-icon">
            <img src={`/images/email-1.png`} className="img-fluid" alt='Email'/>
            <a href={`mailto:${footerData.email}`} className="text-black text-decoration">
              {footerData.email}
            </a>
          </div>
        </li>
        <li>
          <div className="contact-icon">
            <img src={`/images/map-marker.png`} className="img-fluid" alt='Marker'/>
            <p dangerouslySetInnerHTML={{ __html: footerData.address }}></p>
          </div>
        </li>
      </ul>
      
    </>
  )
}

export default ContactDetails

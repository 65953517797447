import React from 'react'
import { industries } from '../../../jsons/allJsons'
import CountCard from './countCard'

const Industries = () => {
  return (
    <section className="industry-solution space-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center text-white mb-4">
            <div className="sub-title d-block">
              <p className="text-white">Industries we served.</p>
            </div>
            <h1 className="heading">We have Variety of Industrial Solutions.</h1>
          </div>
        </div>
        <div className="row">
          {industries.map((inData)=>(
            <div className="col-md-4 d-flex" key={inData.id}>
              <CountCard data={inData}/>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Industries
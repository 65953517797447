import React from 'react'
import SmallHeading from '../../common/heading/smallHeading'
import { WhoWeAreData } from '../../../jsons/allJsons'
import { ALLDATA } from '../../../leg/all'
import LazyLoading from '../../../hooks/lazyLoading'

const WhoWeAre = () => {
    const {isLoading, handleImageLoad} = LazyLoading()
  return (
    <section className="space-section">
        <div className="container">
            <div className="row">
                <div className="col-md-7">
                    <div className="aboutus-section">
                    	<div className="row">
							<div className="col-md-12">
								<SmallHeading heading={WhoWeAreData.heading} name={WhoWeAreData.name}/>
							</div>
						</div>
                        <div className="col-md-12">
                        	<p dangerouslySetInnerHTML={{ __html: WhoWeAreData.description }}></p>
                    	</div>
                    </div>
                    <div className="floating-button">
                        <div className="video-showcase">
                            <div className="video-showcase-insight">
                                <div className="play-button">
                                    <img src={`/images/play.png`} className="img-fluid" alt='play'/>
                                </div>
                            </div>
                        </div>
                        <p>{ALLDATA.VIDEOSHOWCASE}</p>
                    </div>
                </div>
                <div className={`col-md-5 ${isLoading?'loader-insight':''}`}>
                {isLoading &&  
                    <img 
                        src="/images/loading-waiting.gif" 
                        className="img-fluid" 
                        alt="Loading..."
                    />}
                	<img src={WhoWeAreData.url}
                        className="img-fluid" 
                        alt={WhoWeAreData.heading}
                        style={isLoading ? { display: "none" } : {}} 
                        onLoad={handleImageLoad} 
                        onError={handleImageLoad}
                     />
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhoWeAre

import React from 'react'
import IndustriesServed from '../components/industries/industries-served'
import AerospaceAndDefence from '../components/industries/aerospace-and-defence'
import Automotive from '../components/industries/automotive'
import IndustrialEquipment from '../components/industries/industrial-equipment'
import EngineeringAndConstruction from '../components/industries/engineering-and-construction.'
import Distribution from '../components/industries/distribution'
import FoodAndBeverages from '../components/industries/food-and-beverages'
import CountersSection from '../components/common/counter'
import OurClients from '../components/common/our-clients'

const Industries = () => {
  return (
    <>
      <section className="about-bg our-work"></section>
      <IndustriesServed/>
      <AerospaceAndDefence/>
      <Automotive/>
      <IndustrialEquipment/>
      <EngineeringAndConstruction/>
      <Distribution/>
      <FoodAndBeverages/>
      <CountersSection/>
      <OurClients/>
    </>
  )
}

export default Industries
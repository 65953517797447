import React from 'react'
import { contectHelp } from '../../../jsons/allJsons'
import { ALLDATA } from '../../../leg/all'
import { Link } from 'react-router-dom'

const ContactHelp = () => {
  return (
	<section className="coding-skill space-section">
		<div className="container">
			<div className="row">
				<div className="col-md-9">
					<div className="sub-title">
						<p className="text-white">{contectHelp.name}</p>
					</div>
					<h1 className="heading text-left" dangerouslySetInnerHTML={{ __html: contectHelp.description }}></h1>
				</div>
				<div className="col-md-3">
					<Link to={`/contact-us`} className="btn expert-btn">{ALLDATA.TALKTOOUREXPERTS}</Link>
				</div>
				
			</div>
		</div>
	</section>
  )
}

export default ContactHelp
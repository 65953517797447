import React from 'react'
import White from '../sections/white'
import { allIndustries } from '../../../jsons/allJsons'

const EngineeringAndConstruction = () => {
  return (
    <White data={allIndustries.engineering}/>
  )
}

export default EngineeringAndConstruction

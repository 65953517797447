import React from 'react'
import LazyLoading from '../../../hooks/lazyLoading'

const TeamMember = ({data}) => {
    const {isLoading, handleImageLoad} = LazyLoading()
    
  return (
    <div className="team-member">
        <div className={`team-img ${isLoading ?'loader-box':''}`}>
            {isLoading &&  
            <img 
                src="/images/loading-waiting.gif" 
                className="img-fluid" 
                alt="Loading..."
            />} 
            <img  
                src={data.url} 
                className="img-fluid" 
                alt={data.name}
                style={isLoading ? { display: "none" } : {}} 
                onLoad={handleImageLoad} 
                onError={handleImageLoad}
            />
            
        </div>
        <div className="team-detail">
            <h3>{data.name}</h3>
            <p>{data.position}</p>
        </div>
        <div className="team-detail-outsight">
            <div className="team-detail-insight">
                <h3>{data.name}</h3>
                <p>{data.position}</p>
                <ul className="social-list-icon">
                    {data.socialLinks.map((social)=>(
                        <li key={social.id}>
                            <div className="social-icons">
                                <img src={social.url} className="img-fluid" alt={social.name} />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </div>
  )
}

export default TeamMember

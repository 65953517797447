import React from 'react'
import Contact from '../components/contact-us'

const ContactUs = () => {
  return (
    <>
     <section className="contact-bg"></section>
	 <Contact/>
	
    </>
  )
}

export default ContactUs
import React from 'react'
import { contatctData } from '../../../jsons/allJsons'
import { ALLDATA } from '../../../leg/all'
import ContactFormController from './contactFormController'

const ContactForm = () => {
	const {
		handleChange,
		handleSubmit,
		loading,
		formData,
		successMessage,
		errorMessage,
		setSuccessMessage,
		setErrorMessage,
		errors
	  } = ContactFormController()
  return (
    <div className="contact-us">
		 {successMessage && <div className="alert alert-success alert-dismissible fade show success_msg" role="alert">
			<strong>{successMessage}</strong>
			<button type="button" onClick={() => setSuccessMessage(false)} className="close" data-dismiss="alert" aria-label="Close">
			<span aria-hidden="true">&times;</span>
			</button>
		</div>}
		{errorMessage && <div className="alert alert-danger alert-dismissible fade show success_msg" role="alert">
			<strong>{errorMessage}</strong>
			<button type="button" onClick={() => setErrorMessage(false)} className="close" data-dismiss="alert" aria-label="Close">
			<span aria-hidden="true">&times;</span>
			</button>
		</div>}
		<form onSubmit={handleSubmit}>
			<div className="row">
				<div className="col-md-12 mb-4">
					<h1 className="heading-2">{contatctData.contactForm.name}</h1>
					<p>{contatctData.contactForm.description}</p>
				</div>
				<div className="col-md-12">
					<div className="form-group">
						<input 
							type="text"
							name="name"
							value={formData.name}
							onChange={handleChange}
							className="form-control" placeholder={ALLDATA.ENTERENAME} 
						/>
						{errors.name && <small className="text-danger">{errors.name}</small>} 

					</div>		
				</div>
				<div className="col-md-12">
					<div className="form-group">
						<input  
							type="text"
							name="email"
							value={formData.email}
							onChange={handleChange}
							className="form-control" placeholder={ALLDATA.ENTEREMAIl} 
						/>
						{errors.email && <small className="text-danger">{errors.email}</small>} 

					</div>		
				</div>
				<div className="col-md-12">
					<div className="form-group">
						<textarea 
							name="message"
							value={formData.message}
							onChange={handleChange}
							className="form-control" rows="3" cols="3"   placeholder={ALLDATA.MESSAGE}></textarea>	
						{errors.message && <small className="text-danger">{errors.message}</small>} 

					</div>		
				</div>
				<div className="col-md-6">
					<div className="form-group">
						<button type='submit' className="btn btn-white" disabled={loading}>
							{loading ? ALLDATA.SUBMITTING : ALLDATA.QUOTE} 
						</button>
					</div>		
				</div>
			</div>
		</form>
	</div>
  )
}

export default ContactForm

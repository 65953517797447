import React from 'react'
import { ALLDATA } from '../../../leg/all'
import { Link } from 'react-router-dom'

const CountCard = ({data}) => {
  return (
    <div className="count-section">
        <div className="count-num">{data.count}</div>
        <div className="count-description">
            <h3>{data.name}</h3>
            <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
        </div>
        <Link to={`/industries#${data.target}`} className="click-here text-decoration">{ALLDATA.LEARNMORE}</Link>
    </div>
  )
}

export default CountCard
import React from 'react'
import Blue from '../sections/blue'
import { allIndustries } from '../../../jsons/allJsons'

const Distribution = () => {
  return (
    <Blue data={allIndustries.distribution}/>
  )
}

export default Distribution

import React from 'react'
import LazyLoading from '../../../hooks/lazyLoading'

const White = ({data}) => {
  const {isLoading, handleImageLoad} = LazyLoading()
  return (
    <section className="space-section bg-blue-light" id={data.target}>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="industry-solutions">
                <h3>{data.name}</h3>
                <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
            </div>
          </div>
          <div className={`col-md-5 text-end ${isLoading?'loader-insight h-200':''}`}>
          {isLoading &&  
            <img 
                src="/images/loading-waiting.gif" 
                className="img-fluid" 
                alt="Loading..."
            />}
            <img src={data.url} 
              className="img-fluid" 
              alt={data.name}
              style={isLoading ? { display: "none" } : {}} 
              onLoad={handleImageLoad} 
              onError={handleImageLoad}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default White

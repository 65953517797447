import React from 'react'
import SmallHeading from '../../common/heading/smallHeading'

const IndustriesServed = () => {
  return (
    <section className="space-section pt-4 pb-4">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <SmallHeading heading={'Industries we served.'} name={'We have Variety of Industrial Solutions.'}/>
          </div>
			  </div>
      </div>
    </section>
  )
}

export default IndustriesServed

export const ALLDATA = {
    COPYRIGHT:"Copyright © 2024 Artha Infinity . | All Rights Reserved",
    REQUESTAQUOTE:"Request a Quote",
    TALKTOOUREXPERTS:"Talk to our experts",
    LEARNMORE:"Learn more",
    VIDEOSHOWCASE:"Video Showcase",
    OURCLIENTS:"We Love Our Clients",
    ENTERENAME:"Enter Your Name",
    ENTEREMAIl:"Enter Your Email",
    MESSAGE:"Message",
    QUOTE:"Get a Free Quote",
    SUBMITTING:"Submitting..."
}

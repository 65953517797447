import React from 'react'
import { infor } from '../../../jsons/allJsons'
import { ALLDATA } from '../../../leg/all'
import { Link } from 'react-router-dom'

const Infor = () => {
  return (
    <section className="space-section">
        <div className="container-fluid">
            <div className="row d-flex justify-content-center">
                <div className="col-md-11">
                    <div className="infor-bg-img">
                        <div className="row">
                            <div className="col-md-3 text-center">
                                <img src={infor.url} className="img-fluid" alt={infor.id}/>
                            </div>
                            <div className="col-md-9">
                                <div dangerouslySetInnerHTML={{ __html: infor.description }}></div>
                            <Link to={`/contact-us`} className="btn expert-btn">{ALLDATA.TALKTOOUREXPERTS}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
export default Infor
import React from 'react'
import Blue from '../sections/blue'
import { allIndustries } from '../../../jsons/allJsons'

const AerospaceAndDefence = () => {
  return (
    <Blue data={allIndustries.aerospace}/>
  )
}

export default AerospaceAndDefence

import React from 'react'
import { client } from '../../../jsons/allJsons'
import ClientCard from './clientCard'
import { ALLDATA } from '../../../leg/all'
import MainHeading from '../heading/mainHeading'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const OurClients = () => {
    const responsive = {
		superLargeDesktop: {
		  breakpoint: { max: 4000, min: 3000 },
		  items: 2
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 2
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 2
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1
		}
	  };
  return (
        <section className="space-section our-client">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-5">
                        <div className="sub-title justify-content-center">
                            <MainHeading name={ALLDATA.OURCLIENTS}/>
                        </div>
                    </div>
                </div> 
                <Carousel  
                    swipeable={false}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={ true}
                    keyBoardControl={true}
                    customTransition="transform 500ms ease-in-out"
                    transitionDuration={500}
                    containerclassName="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={'desktop'}
                    itemclassName="mx-2"
                >
                    {client.map((cData)=>(
                        <ClientCard data={cData}  key={cData.id}/> 
                    ))}
                </Carousel>
            </div>
        </section>
    )
}

export default OurClients